import React, { FC } from "react";
import "./booking-list.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { usePagination } from "../../../../contexts/PaginationContext";
import Pagination from "../../../../components/Pagination";
import { BOOKING_PLATFORM_DISCOUNTED, BOOKING_PLATFORM_THIRDPARTY, BookingPlatform, getStatusObj, IStatusObj } from "../../../../utils/constants";
import { BookingsType } from "../../bookings";
import { formatPhoneNumber } from "../booking-details/booking-details";
import { DateTime } from "luxon";
import { mapTimeZone } from "../../../../utils/common";

const getUserName = (user: any): string => {
  const strippedName = user?.name?.replace(/\s+/g, '');

  // sometimes the server has ' undefined' for the name, we'll just strip it and check anyway. this assumes
  // guest if there is also no first or last name provided.
  if (user?.name && strippedName !== 'undefined') {
    return user.name;
  }
  else if (user?.firstname) {
    if (user.lastname) {
      return `${user.firstname} ${user.lastname}`;
    }
    return user.firstname;
  }
  return 'Guest';
}


const BookingList = ({ data, type }: { data: any, type?: BookingsType }) => {
  const { currentPage, totalItems, itemsPerPage } = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
   {data?.length>0 && 
    <div className="courseslistWrapper">
      <div className="tableLayout">
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                {/* <th>Course</th> */}
                {/* <th>Tee Time</th> */}
                <th>Golfers</th>
                {/* <th>Holes</th> */}
                <th>Status</th>
                {!type && <th>Platform</th>}
                <th>Booked On</th>
                <th>Payment</th>
                <th className="width-50">&nbsp;</th>
              </tr>
            </thead>

            <tbody>
              {data?.map((e: any, j: number) => {
                const status = getStatusObj(e?.status?.value)
                let user = (e?.userId) ? e?.userId : e?.user;
                // let userName= (user?.name) ? user?.name : (user?.firstname) ? `${user?.firstname} ${user?.lastname}` : 'Guest'
                const userName = getUserName(user);

                let userPhone = 'n/a';
                if (e?.user?.phone?.phone) {
                  userPhone = `+${e.user?.phone?.countryCode} ${formatPhoneNumber(e.user?.phone?.phone)}`;
                } else if(e?.userId?.phone?.number){
                  userPhone = `+${e.userId?.phone?.countryCode} ${formatPhoneNumber(e.userId?.phone?.number)}`;
                } else if (e?.user?.phone) {
                  // userPhone = courseDetails.user.phone;
                  userPhone = formatPhoneNumber(e.user.phone);
                }

                // let userPhone = (e?.bookingType==1 && e?.user?.phone)? e?.user?.phone: (e?.user?.phone?.phone)? `+${e.user?.phone?.countryCode}${e.user?.phone?.phone}` : 'n/a';
                return (
                  <tr key={j}>
                    <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                    <td>
                      <div className="userContent align-items-center">
                        <span className="imgBlock rounded-circle">
                          <img
                            src={e?.profileImage?.[0]?.path ?? "../../assets/images/default-user.png"}
                            alt=""
                            className="img-fluid"
                          />
                        </span>
                        <div className="content">
                          <NavLink state={{ id: e._id }} to={'detail'}>
                            <h5 className="mb-0" style={{ textTransform: 'capitalize' }}>{userName?.trim()?.length===0 ? 'Guest': userName?.trim()}</h5>
                          </NavLink>
                        </div>
                      </div>
                    </td>
                    <td style={{whiteSpace: 'nowrap'}}>{user?.email ? user?.email : 'n/a'}</td>
                    <td style={{whiteSpace: 'nowrap'}}>{userPhone}</td>
                    {/* <td>{e?.teesheets[0]?.club?.name}</td> */}
                    {/* <td>
                      {`${dayjs(e.dateTime).format('MMMM D YYYY, h:mm a')}`}
                    </td> */}
                    <td>{e?.noOfGuest}</td>
                    {/* <td>{e?.teesheets[0]?.course?.holes}</td> */}
                    <td>
                      <label style={{ background: status.color, color: status.fontColor, textTransform: 'capitalize' }} className={`mb-0 status`}>
                        {status.label}
                      </label>
                    </td>
                    { !type && <td style={{textAlign:'center'}}>
                    {(e?.bookingType)?((e?.bookingType === BookingPlatform.BOOKING_PLATFORM_DISCOUNTED)?BOOKING_PLATFORM_DISCOUNTED:BOOKING_PLATFORM_THIRDPARTY):'n/a'}
                    </td>}
                    <td style={{minWidth: '200px'}}>
                      {`${ DateTime.fromMillis(e?.createdAt, {zone:mapTimeZone(e?.teesheets[0]?.teesheets[0]?.club)}).toFormat(
                              "hh:mm a, EEE dd MMM yyyy"
                            )  }`}
                    </td>
                    <td>${(e?.isLuckyBooking)?"0.00":e?.totalPrice.toFixed(2)}</td>
                    <td>
                      <div className="listActionDropdownBlock">
                        <Dropdown align="end">
                          <div className="text-center">
                            <Dropdown.Toggle className="toggleButton">
                              <em className="fa-solid fa-ellipsis-vertical"></em>
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu>
                            <NavLink state={{ id: e._id }} to={'detail'} className="dropdown-item fw600">
                              View
                            </NavLink>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {
              totalItems > 10 && 
              <Pagination 
                onNext={
                  (page) => { 
                    searchParams.set('page', `${page + 1}`); 
                    setSearchParams(searchParams); 
                  }
                } 
                onPrev={
                  (page) => { 
                    searchParams.set('page', `${page - 1}`); 
                    setSearchParams(searchParams); 
                  }
                }
                />
            }
          </div>
        </div>
      </div>
    </div>
}
</>
  );
};

export default BookingList;
