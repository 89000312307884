import { useEffect, useState } from "react";
import ModalContainer from "../../../../components/Modal";
import { Check, X } from "react-bootstrap-icons";

export default ({ userStatus, email, markUserActiveInactive, updatedStatusData, portal, action="accept" }: any) => {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(false)
    }, [updatedStatusData]);

    // if (status === 'Ready') {
    return (
        <>
            <ModalContainer
                action={() => {markUserActiveInactive({ email, portal, status:(action==='accept')?'accepted':'rejected' }); setShowModal(false);}}
                onHide={() => setShowModal(false)}
                title='Merchant Signup Request'
                desc={`Are you sure you want to ${action == 'reject' ? 'Reject' : 'Accept'} this merchant ?`}
                showModal={showModal} />
            <button
                type="button"
                className={`button button-${(action=='accept'?'primary':'danger')} button-small button-rounded fw100 px-3`}
                style={{margin:"5px"}}
                // className="button button-sm button-rounded button-primary button-filter fontsize-14 px-3"
                onClick={() => {
                    setShowModal(true)
                    // markPayoutComplete(id)
                }}>{action == 'accept' ?  <Check color="white" size={25}/>: <X color="white" size={25}/>}</button>
        </>

    );
    // } else {
    //     return (
    //         <label className={`mb-0 status status--active`}>
    //             {status}
    //         </label>
    //     )
    // }

}