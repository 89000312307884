import React, { FC } from "react";
import "./payout-list.scss";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import dayjs from "dayjs";
import Pagination from "../../../../components/Pagination";
import { usePagination } from "../../../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../../../components/integolf-data-not-found/integolf-data-not-found";

interface TransactionsListProps { }
const getStatusDetail = (status: string) => {
  switch (status) {
    case 'COMPLETED':
      return {
        label: 'Active',
        class: 'status--active'
      }
    case 'REFUNDED':
      return {
        label: 'Blocked',
        class: 'status--warning'
      }
    default:
      return {
        label: 'Blocked',
        class: 'status--danger'
      }
  }
}
const TransactionsList = ({ data = [] }: any) => {
  const { currentPage, totalItems,itemsPerPage } = usePagination();
  return (
    <>
      {data?.length > 0 ?
        <div className="transactionlist">
          <div className="tableLayout">
            <div className="table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>User</th>
                    <th>Provider</th>
                    <th>Course Name</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th className="width-50">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((e: any, j: number) => {
                    const statusDetail = getStatusDetail(e?.status);
                    return (
                      <>
                        <tr>
                          <td>{(j + 1) + (currentPage - 1) * itemsPerPage}</td>
                          <td>{dayjs(e?.createdAt).format('DD MMM YYYY hh:mm a')}</td>
                          <td>
                            <div className="userContent align-items-center">
                              <span className="imgBlock rounded-circle">
                                <img
                                  src={e?.profileImage?.path ?? "../../assets/images/default-user.png"}
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                              <div className="content">
                                <h5 className="mb-0">{`${!!e?.user?.firstname ? e?.user?.firstname : 'Guest'} ${e?.user?.lastname ?? ''}`}</h5>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="userContent align-items-center">
                              <span className="imgBlock rounded-circle">
                                <img
                                  src={e?.profileImage?.path ?? "../../assets/images/default-user.png"}
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                              <div className="content">
                                <h5 className="mb-0">{`${(e?.merchant?.firstname || 'merchant')} ${e?.merchant?.lastname ?? ''}`}</h5>
                              </div>
                            </div>
                          </td>
                          <td>{e?.club?.name}</td>
                          <td>${Number(e?.total_amount).toFixed(2)}</td>
                          <td>
                            {
                              <label style={{ textTransform: 'capitalize' }} className={`mb-0 status ${statusDetail?.class}`}>
                                {e?.status?.toLowerCase()}
                              </label>
                            }
                          </td>
                          {/* <td>
                      <div className="listActionDropdownBlock">
                        <Dropdown align="end">
                          <div className="text-center">
                            <Dropdown.Toggle className="toggleButton">
                              <em className="fa-solid fa-ellipsis-vertical"></em>
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu>
                            <NavLink to={"/payout/detail"} state={{id: e._id}} className="dropdown-item fw600">
                              View
                            </NavLink>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td> */}
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="paginationBlock">
              <div className="row gutters-5 align-items-center justify-content-between">
                {totalItems > 10 && <Pagination />}
              </div>
              {/* <div className="row gutters-5 align-items-center justify-content-between">
            <div className="col-auto">
              <h6 className="mb-0">
                Showing <strong>1</strong> to <strong>10</strong> of{" "}
                <strong>100</strong> Results
              </h6>
            </div>
            <div className="col-auto">
              <div className="row gutters-5">
                <div className="col-auto">
                  <button type="button" className="button button-sm fw600 px-3">
                    Previous
                  </button>
                </div>
                <div className="col-auto">
                  <button type="button" className="button button-sm fw600 px-3">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div> */}
            </div>
          </div>
        </div> : <IntegolfDataNotFound message={"Payouts not found"} />}
    </>
  );
};

export default TransactionsList;
