import React, { FC, useEffect, useState } from "react";
import "./course-detail.scss";
import { NavLink, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import Edit from "../course-edit/course-edit";
import IntegolfLoader from "../../../../components/integolf-loader/integolf-loader";
import { convertTime24to12, PAYOUT_INTERVAL_TO_LABEL_MAPPING } from "../../../../models/club";

import { FilterItemType } from "../filter-bar/filter-bar";
import { IFilterItem, getFilterItemFromIndex } from "../../../bookings/components/filter-bar/filter-bar";
import { toast } from "react-toastify";
import StatusChangeModal from "../modal/statusModal";
import { DateTime } from "luxon";
import { capitalizeFullString, mapWeatherCodeType } from "../../../../utils/common";
import WeatherDiscountPopup from "../weather-discount-modal/weather-discount-modal";
import DeleteModal from "../delete-modal/delete-modal";
import InstantDiscountModel from "../instant-discount-request/instant-discount-modal";
import { Dropdown } from "react-bootstrap";

interface CourseDetailProps { }

function mapArrays(ids: any, objects: any) {
  return ids?.map(({ id, name }: any) => {
    const matchedObjects = objects.filter((obj: any) => obj.discountProfileId === id);
    return matchedObjects?.length > 0 ? { id, name, matchedObjects } : null;
  });
}
function buildLabel(days: any, discount: any) {
  let day = '';
  switch (days) {
    case -1:
      day = `Default booking: ${discount}%`;
      break;
    case 0:
      day = `Current day booking: ${discount}%`;
      break;
    default:
      day = `Day ${days} booking: ${discount}%`;
  }
  return day;
}

function mappedClubTimings(clubTimings: any) {
  return clubTimings
    ?.filter((timingObject: any) => timingObject !== null && Object.keys(timingObject)?.length !== 0)
    ?.map((timingObject: any) => ({ ...timingObject, startMinutes: timingObject?.startMinutes || '00', endMinutes: timingObject?.endMinutes || '00', deleted: false }));

}

function areWeatherDiscountedDatesSame(dateFromWeather: string, dateFromWeatherDiscount: string) {
  return DateTime.fromFormat(dateFromWeather, "yyyy-MM-dd").diff(DateTime.fromFormat(dateFromWeatherDiscount, "yyyy-MM-dd"), "days")?.days === 0
}

const CourseDetail: FC<CourseDetailProps> = () => {
  const [showTab, setShowTab] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false)
  const [fullDescription, setFullDescription] = useState(true);
  const [selectedKey, setSelectedKey] = useState("");
  const [clubRatingsData, setClubRatingsData] = useState("");
  const [isEdited, setIsEdited] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterItem, setFilterItem] = useState<IFilterItem | undefined>();
  const [clubRatingsError, setClubRatingsError] = useState("");
  const [status, setStatus] = useState<Number>();
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [showBadWeatherPopup, setShowBadWeatherPopup] = useState<boolean>(false);
  const [selectedBadWeatherDiscountDate, setSelectedBadWeatherDiscountDate] = useState<Date>(new Date());
  const [showDeleteBadWeatherPopup, setShowDeleteBadWeatherPopup] = useState(false)
  const [selectedBadWeatherDiscount, setSelectedBadWeatherDiscount] = useState({ _id: null, date: null });
  const [showDiscountModel, setShowDiscountModel] = useState<boolean>(false);
  const [modalType, setModalType] = useState("");
  const [selectedDiscount, setSelectedDiscount] = useState<any>({})

  const closeDiscountModel = () => {
    setShowDiscountModel(false)
  }

  const onDiscountDelete = (discount: any) => {
    setSelectedDiscount(discount)
    setModalType("delete")
    setTimeout(() => {
      setShowDiscountModel(true)
    }, 300);
  }
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();


  const courseInfo = () => {
    return {
      'holes': courseDetails?.course?.holes,
      'par': courseDetails?.course?.par || 0,
      'slope': courseDetails?.course?.slope || 0,
      'distance': courseDetails?.course?.distance || 0
    }
  }


  const getCurrentDiscount = (existingDiscount: any) => {
    let dis = hasExistingDiscounts?.filter((discount: any) => discount?.discountId === existingDiscount)?.length > 0 ? true : false
    return dis;
  }

  const getavailableDiscounts = (discounts: any) => {
    return discounts.map((discount: any) => {
      return {
        "discountId": discount?._id, "previousDays": discount?.days, "previousDiscount": discount?.discount, "updatedDays": discount?.days,
        "updatedDiscount": discount?.discount,
        "type": discount?.type,
        "statusValue": discount?.status,
        "updated": false,
        "confirmation": getCurrentDiscount(discount?._id)
      }
    })
  }

  const addMerchantDefaultProducts = (e: any) => {
    e.preventDefault();
    const payload = { clubId: clubId, courseId: id };
    addDefaultProducts(payload);
  }


  const checkContentEdited = (content: any) => {
    setIsEdited(content);
  }
  const getimagesInfo = (clubPhotosId: any, photos: any) => {
    let photoGallery: any = [];
    photos.map((item: any, index: any) => {
      photoGallery.push({ _id: clubPhotosId[index], path: item.path });
    })
    return photoGallery;
  }
  const basicsInfo = () => ({
    'walkingAllowed': courseDetails?.club?.walkingAllowed,
    'metalSpikesAllowed': courseDetails?.club?.metalSpikesAllowed,
    'dressCode': courseDetails?.club?.dressCode,
    'drivingRange': courseDetails?.club?.drivingRange,
    'pitchingArea': courseDetails?.club?.pitchingArea,
    'puttingGreen': courseDetails?.club?.puttingGreen,
    'teachingPro': courseDetails?.club?.teachingPro,
    'cartsForRentalAvailable': courseDetails?.club?.cartsForRentalAvailable,
    'clubsForRentalAvailable': courseDetails?.club?.clubsForRentalAvailable,
    "caddiesForRentalAvailable": courseDetails?.club?.caddiesForRentalAvailable
  });

  const getClubRatings = async (accessToken: any, clubId: any) => {
    if (clubId) {
      const response = await axios.get(`${process.env.REACT_APP_DATACOLLECTION_API_BASEURL}/integolf/rates/${clubId}`, {
        headers: {
          'Accesstoken': `${accessToken}`,
        },
      });
      return response;
    }
  }

  const addingDefaultProducts = async (payload: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/addProducts`, payload, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  }

  const getDefaultProductsInfo = async (payload: any) => {
    const accessToken: any = localStorage.getItem("accessToken");
    payload['isDeleted'] = false;
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/products`, {
      params: payload,
      headers: {
        'Accesstoken': accessToken,
      }
    });
    return response.data;
  }

  const getTimeFormat = (time: string) => {
    let splitTimeString = time.split(":")
    let convertedString = convertTime24to12(splitTimeString[0], splitTimeString[1])
    return convertedString
  }

  const onAddInstantDiscount = (data: any) => {
    let currentDate = DateTime.now().toFormat('yyyy-MM-dd');
    data.date = currentDate;
    data.clubId = clubId
    data.courseId = id
    addInstantDiscountFn(data)
    closeDiscountModel();
    setModalType("");
  }


  const deleteDiscount = (data: any) => {
    deleteInstantDiscountFn(data);
    closeDiscountModel();
    setModalType("");
  }

  const updateCourseStatus = (status: number, e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setStatus(status);
    setShowStatusModal(!showStatusModal);
  }

  const changeCourseStatus = async (status: Number) => {
    const accessToken = localStorage.getItem("accessToken")
    const response = await axios.put(`${process.env.REACT_APP_INTEGOLF_COURSES_PORTAL}/merchant/course`, {
      "_id": id,
      "status": status
    }, {
      headers: {
        "x-auth-jwt": `${accessToken}`,
      },
    });
    return response.data;
  }

  const fetchTeeTimes = async (data: any, accessToken: any) => {
    toast.success("Starting tee sheet sync for club")
    const response = await axios.post(`${process.env.REACT_APP_DATACOLLECTION_API_BASEURL}/integolf/fetchTeetimes`, data, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    })
      .catch(err => {
        toast.error(err?.response?.data?.message ?? 'Sync tee sheet failed')
      })
    if (response?.data) {
      toast.success(response?.data?.message)
    }
    return response?.data ?? {};
  };

  const badWeatherDiscountReq = async (data: any, accessToken: any) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/addBadWeatherDiscount`, data, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    })
      .catch(err => {
        toast.error(err?.response?.data?.response ?? 'Something went wrong')
      })
    if (response?.data) {
      toast.success("Bad weather discount request sent successfully")
    }
    return response?.data ?? {};
  };

  const badWeatherDiscountDelete = async (data: any, accessToken: any) => {
    const response = await axios.put(`${process.env.REACT_APP_ADMIN_API_BASEURL}/deleteBadWeatherDiscount/${data._id}`, {}, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    })
      .catch(err => {
        toast.error(err?.response?.data?.response ?? 'Something went wrong')
      })
    if (response?.data) {
      toast.success("Bad weather discount request deleted successfully")
    }
    return response?.data ?? {};
  };

  const addInstantDiscount = async (data: any) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/addInstantDiscount`, data, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  }

  const deleteInstantDiscount = async (data: any) => {
    data.clubId = clubId;
    const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API_BASEURL}/deleteInstantDiscount`, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
      data: data,
    });

    return response.data;
  }

  const getCourseDetail = async (accessToken: string) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchCourse`, {
      "courseObjectId": id
    }, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };

  const existingClubRatings = () => {
    return {
      tourismPortalRate: courseDetails?.club?.tourismPortalRate?.rateCode,
      discountedPortalRate: courseDetails?.club?.discountedPortalRate?.rateCode
    }
  }
  const accessToken = localStorage.getItem('accessToken');
  const { mutate: teeTimesSync, isLoading: syncIsLoading } = useMutation('syncCourseAfterActivate', (data) => fetchTeeTimes(data, accessToken!))
  const { mutate: badWeatherPostReq, data: badWeatherReqData } = useMutation('badWeatherPost', (data) => badWeatherDiscountReq(data, accessToken!))
  const { mutate: badWeatherDeleteReq, data: badWeatherDeleteData } = useMutation('badWeatherDelete', (data) => badWeatherDiscountDelete(data, accessToken!))


  const { isLoading, isSuccess, error, data, mutate: courseDetailFn } = useMutation('users', () => getCourseDetail(accessToken!));
  const { isError, isSuccess: clubRatingsSuccess, mutate: checkClubRates, data: clubRatesData, error: clubRateError } = useMutation('clubRates', () => getClubRatings(accessToken!, clubId), {
    onError: (error: any) => {
      setClubRatingsError(error?.response?.data?.message);
    },
  });
  const { isLoading: gettingProducts, isError: defaultProductsGetError, isSuccess: defaultProductsGetSuccess, mutate: getMerchantDefaultProducts, data: defaultProductsInfo } = useMutation('getproducts', getDefaultProductsInfo);
  const { isLoading: gettingProductsAddding, isSuccess: addDefaultProductsSuccess, mutate: addDefaultProducts, data: defaultProductsData, error: defaultProductsError } = useMutation('defaultProducts', addingDefaultProducts, {
    onSuccess: () => {
      toast.success("Products added successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.response?.message || error?.response?.data?.message || "Submission failed");
      const payload = { clubId, courseId: id, isDeleted: false };
      getMerchantDefaultProducts(payload);
    }
  });
  const { isLoading: statusUpdationInProgress, isSuccess: statusChangeSuccess, error: statusChangeError, data: courseStatusData, mutate: statusChangeFn } = useMutation("statuschange", changeCourseStatus,
    {
      onSuccess: () => {
        toast.success("Status updated successfully")
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message || error?.response?.data?.response?.message || "Something went wrong");
      }
    });
  const { isLoading: addInstantDiscountInProgress, isSuccess: addInstantDiscountStatus, error: addInstantDiscountError, data: addInstantDiscountData, mutate: addInstantDiscountFn } = useMutation("addinstantdiscount", addInstantDiscount,
    {
      onSuccess: () => {
        toast.success("Discount added successfully")
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message || error?.response?.data?.response?.message || "Something went wrong");
      }
    });

  const { isLoading: deleteInstantDiscountInProgress, isSuccess: deleteInstantDiscountStatus, error: deleteInstantDiscountError, data: deleteInstantDiscountData, mutate: deleteInstantDiscountFn } = useMutation("deleteinstantdiscount", deleteInstantDiscount,
    {
      onSuccess: () => {
        toast.success("Discount deleted successfully")
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message || error?.response?.data?.response?.message || "Something went wrong");
      }
    });

  useEffect(() => {
    courseDetailFn();
  }, [courseDetailFn, isEdited, statusChangeSuccess, statusChangeError, addDefaultProductsSuccess, deleteInstantDiscountError, addInstantDiscountStatus, addInstantDiscountError, deleteInstantDiscountStatus, badWeatherReqData, badWeatherDeleteData])

  useEffect(() => {
    /**effect for if status is changed to active then sync teesheet for current day */
    if (courseStatusData && courseStatusData.response && courseStatusData.response.status === 1) {
      const courseData = data?.response?.result?.[0];
      const clubId = courseData?.club?._id;
      const courseId = courseData?.course?._id;
      let currentDateMillis = Date.now();
      let reqData: any = {
        from: currentDateMillis,
        to: currentDateMillis,
        clubId,
        courseId
      }
      /**start sync after 2 sec delay */
      setTimeout(() => {
        teeTimesSync(reqData)
      }, 2000)
    }
  }, [statusChangeSuccess, courseStatusData])

  useEffect(() => {
    if (isSuccess) {
      let clubId = data?.response?.result?.[0]?.club?._id;
      checkClubRates(clubId);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (addDefaultProductsSuccess === true) {
      const payload = { clubId, courseId: id, isDeleted: false };
      getMerchantDefaultProducts(payload);
    }

  }, [addDefaultProductsSuccess])

  useEffect(() => {
    if (clubRatingsSuccess) {
      setClubRatingsData(clubRatesData?.data?.results)
    }
  }, [clubRatingsSuccess])

  useEffect(() => {
    if (isSuccess && data) {
      const payload = { clubId: data?.response?.result?.[0]?.club?._id, courseId: id };
      getMerchantDefaultProducts(payload);
    }
  }, [isSuccess, data])

  useEffect(() => {
    const selectedFilter = filterItem?.filterText;

    if (!filterItem || selectedFilter === FilterItemType.COURSE_DETAILS) {
      courseDetailFn(undefined);
    }
    else if (selectedFilter === FilterItemType.BOOKINGS) {
      // GET BOOKINGS
    }
    else if (selectedFilter === FilterItemType.REVIEWS) {
      // GET REVIEWS
    }
  }, [courseDetailFn, filterItem]);

  const handleEditModal = (key: any) => {
    setSelectedKey(key);
    setShowEditModal(!showEditModal)
  }

  const setDescriptionToogle = () => {
    setFullDescription(!fullDescription)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }

  const badWeatherDiscountRequestHandler = (data: any) => {
    badWeatherPostReq(data);
    setShowBadWeatherPopup(false);
  }

  const checkInstantDiscountButtonVisibility = (instantDiscountList: any[]): boolean => {

    if (instantDiscountList?.length === 0) {
      return true; // Condition 1: Show button if the list is empty.
    }

    const hasAdminFalse = instantDiscountList?.some(({ addedByAdmin }) => !addedByAdmin);
    const allAdminTrue = instantDiscountList?.every(({ addedByAdmin }) => addedByAdmin);
    const noneAccepted = instantDiscountList?.every(({ status }) => status !== 'accepted');
    const allRejectedAdmin = instantDiscountList?.every(({ status, addedByAdmin }) => status === 'rejected' && addedByAdmin);
    const hasPendingAdmin = instantDiscountList?.some(({ status, addedByAdmin }) => status === 'pending' && addedByAdmin);

    // Evaluate conditions
    if (hasAdminFalse) {
      return false; // Hide button if any discount wasn't added by admin.
    }

    if (hasPendingAdmin) {
      return false; // Hide button if any admin-added discount is pending.
    }

    if (allAdminTrue && noneAccepted) {
      return true; // Show button if all discounts were added by admin, and none are accepted.
    }

    if (allRejectedAdmin) {
      return true; // Show button if all admin-added discounts are rejected.
    }

    // Default to hiding the button if none of the conditions are met.
    return false;
  };

  const deleteBadWeatherRequestHandler = () => {
    const { _id } = selectedBadWeatherDiscount;
    if (_id) {
      badWeatherDeleteReq({ _id } as any)
    } else {
      toast.error("Something went wrong")
    }
    setShowDeleteBadWeatherPopup(false);
  }
  const courseDetails = data?.response?.result?.[0];
  const tourismPortalClubTimings = courseDetails?.club?.tourismPortalClubTimings;
  const advanceBookingDuration = courseDetails?.club?.advanceBookingDuration
  const discountId = courseDetails?.discountsrequests?.length > 0 ? courseDetails?.discountsrequests[0]._id : ''
  const hasExistingDiscounts = courseDetails?.discountsrequests?.length > 0 ? (courseDetails?.discountsrequests || []).reduce((acc: any, curr: any) => {
    return acc.concat(curr.discounts || []);
  }, []) : [];
  const discountProfileIds = courseDetails?.discountProfiles?.map((each: any) => ({ id: each?._id, name: each?.name }));
  const clubDiscounts = mapArrays(discountProfileIds, courseDetails?.discounts);
  const courseStatus = courseDetails?.course?.status;
  const clubId = courseDetails?.club?._id;
  const currentClubDateTimeLuxon = courseDetails?.currentClubDateTime ? DateTime.fromISO(courseDetails?.currentClubDateTime) : DateTime.now();
  const dateWiseWeatherData = (courseDetails?.dateWiseWeather
    && Object.keys(courseDetails?.dateWiseWeather ?? {})?.length > 0) ?
    Object.entries(courseDetails?.dateWiseWeather)?.filter((([key]) => DateTime.fromFormat(`${key} 23:59`, "yyyy-MM-dd HH:mm").diff(currentClubDateTimeLuxon, "days")?.days > 0))?.slice(0, 3) : []
  const instantDiscountList = courseDetails?.instantDiscounts
  const checkInstantDiscounts = checkInstantDiscountButtonVisibility(instantDiscountList || []);
  const payoutCandenceInformation = PAYOUT_INTERVAL_TO_LABEL_MAPPING.find((item: any) => courseDetails?.merchant?.payoutInterval === item.code)

  return (
    <>
      {!courseDetails || gettingProductsAddding || statusUpdationInProgress || deleteInstantDiscountInProgress || addInstantDiscountInProgress ?
        <IntegolfLoader show /> : <div className="courseDetail">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="courseDetail__card mb-3">
                <div className="courseDetail__infoHead">
                  <div className="row gutters-10 align-items-center">
                    <div className="col-auto">
                      <div className="imgBlock">
                        <img
                          src={courseDetails?.photos?.[0]?.path || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-siQWshbehfw9lvg5pH16YQ8eYJEgLQbkyQ&usqp=CAU"}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h2 className="mb-2 text-capitalize">{courseDetails?.club?.name}</h2>
                          <p className="mb-0 color-600 fw500">
                            {courseDetails?.club?.formattedName || courseDetails?.club?.addressOne}
                          </p>
                        </div>
                        <div>
                          {courseStatus === 1 ? (
                            <button
                              className="button button-primary button-rounded button-sm fontsize-14 fw500 px-3 mb-3 statusbutton button-min-150"
                              onClick={(e) => updateCourseStatus(2, e)}
                            >
                              Deactivate
                            </button>
                          ) : (
                            <button
                              className="button button-primary button-rounded button-min-150 button-sm fontsize-14 fw500 px-3 mb-3 statusbutton"
                              onClick={(e) => updateCourseStatus(1, e)}
                            >
                              Activate
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {showStatusModal && (
                    <StatusChangeModal
                      showModal={showStatusModal}
                      courseId={id}
                      handleClose={() => setShowStatusModal(false)}
                      changeStatus={statusChangeFn}
                      courseStatus={status}
                    />
                  )}
                </div>


                <div className="content-center">
                  <h5 className="courseDetail__cardTitle mb-3">Course Description</h5>
                  {courseStatus === 1 && <em className="fa-solid fas fa-edit cursor-pointer" onClick={() => handleEditModal("description")}></em>}
                </div>


                <p className="course_description">
                  {courseDetails?.club?.description ?
                    <>
                      {fullDescription && courseDetails?.club?.description?.length > 100 ?
                        courseDetails?.club?.description.slice(0, 101) + "..." :
                        courseDetails?.club?.description
                      }

                      {courseDetails?.club?.description?.length > 100 && <span onClick={setDescriptionToogle} className="read-content-link"> {fullDescription ? "Read More" : "Read Less"}</span>}

                    </> : "n/a"}
                </p>

                {selectedKey == "description" && showEditModal && <Edit handleCloseEditModal={handleCloseEditModal} keyVal={"description"} contentVal={courseDetails?.club?.description} clubObjectId={clubId} checkContentEdited={checkContentEdited} courseObjectId={id} merchantId={courseDetails.merchant._id} hasExistingDiscounts={hasExistingDiscounts} discountId={discountId} advanceBookingDuration={advanceBookingDuration} discountProfiles={courseDetails?.discountProfiles} />}
                <div className="content-center">
                  <div className="row pt-2" style={{ flex: 1 }}>
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="mb-2 d-block">Holes</label>
                        <h6 className="mb-0 fw700">{courseDetails?.course?.holes} Holes</h6>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="mb-2 d-block">Par</label>
                        <h6 className="mb-0 fw700">{courseDetails?.course?.par}</h6>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="mb-2 d-block">Slope</label>
                        <h6 className="mb-0 fw700">{courseDetails?.course?.slope}</h6>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="mb-2 d-block">Length</label>
                        <h6 className="mb-0 fw700">{courseDetails?.course?.distance} yards</h6>
                      </div>
                    </div>
                  </div>
                  {courseStatus === 1 && <em className="fa-solid fas fa-edit cursor-pointer" onClick={() => handleEditModal("course")}></em>}
                </div>


                {selectedKey == "course" && showEditModal && <Edit handleCloseEditModal={handleCloseEditModal} keyVal={"course"} contentVal={courseInfo()} clubObjectId={clubId} checkContentEdited={checkContentEdited} courseObjectId={id} merchantId={courseDetails.merchant._id} hasExistingDiscounts={hasExistingDiscounts} discountId={discountId} advanceBookingDuration={advanceBookingDuration} discountProfiles={courseDetails?.discountProfiles} />}
              </div>

              <div className="courseDetail__card mb-3">
                <div className="content-center">
                  <h5 className="courseDetail__cardTitle mb-3">Images</h5>
                  {courseStatus === 1 && <em className="fa-solid fas fa-edit cursor-pointer" onClick={() => handleEditModal("images")}></em>}
                </div>

                <div className="courseDetail__imagesGrid">
                  {courseDetails?.photos?.map((x: any, i: number) => (
                    <div key={i} className="imgBlock">
                      <img
                        src={x.path}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  ))}
                  {selectedKey == "images" && showEditModal && <Edit handleCloseEditModal={handleCloseEditModal} keyVal={"images"} contentVal={getimagesInfo(courseDetails?.club?.photos, courseDetails?.photos)} clubObjectId={clubId} checkContentEdited={checkContentEdited} courseObjectId={id} merchantId={courseDetails.merchant._id} hasExistingDiscounts={hasExistingDiscounts} discountId={discountId} advanceBookingDuration={advanceBookingDuration} discountProfiles={courseDetails?.discountProfiles} />}
                </div>
              </div>

              <div className="courseDetail__card mb-3">
                <div className="content-center">
                  <h5 className="courseDetail__cardTitle mb-4">Bad Weather Discounts</h5>

                </div>

                <div className="courseDetail__weatherGrid mb-3">
                  {dateWiseWeatherData?.length <= 0 && <p>Weather data for course not available or synced</p>}

                  {dateWiseWeatherData?.length > 0 && dateWiseWeatherData.map(([key, value]: any) =>
                  (<div className="weatherWrapper">
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginRight: "5px" }} >
                      <div>
                        {key && DateTime.fromFormat(key, "yyyy-MM-dd").toFormat("EEE, LLL dd")}
                      </div>
                      <img
                        title={value?.weather?.[0]?.description ? capitalizeFullString(value?.weather?.[0]?.description) : "Temperature"}
                        src={mapWeatherCodeType(value?.weather?.[0].icon)}
                        alt="../../../assets/images/icons/weather/Clear_Day_Icon.png"
                        className="img-fluid"
                        width={"40px"}
                        height={"40px"}
                      />
                      <div><strong>{(value?.temp_min) ? `${value.temp_min.toFixed(0)}°C` : ''}</strong>&nbsp; {value?.temp_max ? `${value.temp_max.toFixed(0)}°C` : ''}</div>
                      {
                        courseDetails?.badWeatherDiscounts?.length > 0 && courseDetails.badWeatherDiscounts.map((obj: any) => {
                          /**if dates are same as shown in bad weather section */
                          if (areWeatherDiscountedDatesSame(key, obj.date)) {
                            return <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                              <label className={`${(obj.status === "rejected") ? "discountBadgeWeather strike" : "discountBadgeWeather"}`} title={obj?.status ?? ''}>
                                {obj.discount}%

                                {obj.status === "pending" && <>&nbsp;  <a
                                  href={void 0}
                                  className="closeButton1"
                                  onClick={() => { setSelectedBadWeatherDiscount(obj); setShowDeleteBadWeatherPopup(true) }}
                                >
                                  <em className="fa-solid fa-close"></em>
                                </a> </>}
                              </label>
                              {obj.status === "pending" && <em
                                className="fa-solid fa-clock fontsize-16 text-danger"
                                title={obj?.status ?? ''}
                              ></em>}
                            </div>
                          }
                        })
                      }

                    </div>
                    {/* add icon should be visible only if no active discounts or request is present  */}
                    {(courseDetails?.badWeatherDiscounts || [])?.filter((obj: any) => areWeatherDiscountedDatesSame(key, obj.date) && obj.status !== "rejected")?.length <= 0 && courseStatus === 1 ? <em className="fa-solid fas fa-edit cursor-pointer" onClick={() => { setSelectedBadWeatherDiscountDate(new Date(`${key}T00:00:00`)); setShowBadWeatherPopup(true) }}></em> : ''}
                  </div>)
                  )}

                </div>
              </div>

              {showBadWeatherPopup &&
                <WeatherDiscountPopup
                  show={showBadWeatherPopup}
                  setShow={setShowBadWeatherPopup}
                  selectedDate={selectedBadWeatherDiscountDate}
                  data={courseDetails}
                  submitHandler={badWeatherDiscountRequestHandler}
                />
              }

              {
                showDeleteBadWeatherPopup && <DeleteModal
                  show={showDeleteBadWeatherPopup}
                  setShow={setShowDeleteBadWeatherPopup}
                  title={'Delete Bad Weather Discount'}
                  content={`Are you sure you want to delete bad weather discount request for selected date ${(selectedBadWeatherDiscount?.date) ? DateTime.fromISO(selectedBadWeatherDiscount?.date).toFormat("MM/dd/yyyy") : ""} ?`}
                  submitHandler={deleteBadWeatherRequestHandler}
                />
              }

              <div className="courseDetail__card mb-3">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h5 className="courseDetail__cardTitle mb-0">Instant Discounts</h5>
                  {
                    courseStatus === 1 && checkInstantDiscounts === true &&
                    <button
                      className="button button-primary button-rounded button-sm fontsize-14 fw500 px-3 statusbutton"
                      onClick={() => {
                        setModalType("");
                        setShowDiscountModel(true);
                      }}
                    >
                      Add Instant Discount
                    </button>
                  }

                </div>

                <div className="courseDetail__weatherGrid mb-3">
                  <div className="table-responsive">
                    {instantDiscountList && instantDiscountList.length > 0 ? (
                      <table className="table table-border mb-2">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Discount Given</th>
                            <th>Teesheet Sold</th>
                            <th>Action</th>
                            <th className="width-30">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {instantDiscountList.map((discounts: any, index: number) => (
                            <tr key={index}>
                              <td className="fw700 fontsize-16">{index + 1}</td>
                              <td>
                                <h6 className="mb-0 fw700 fontsize-16">{discounts?.date}</h6>
                              </td>
                              <td>
                                <h6 className="mb-0 fw700 fontsize-16">
                                  {discounts?.startTime ? getTimeFormat(discounts?.startTime) : "n/a"}
                                </h6>
                              </td>
                              <td>
                                <h6 className="mb-0 fw700 fontsize-16">
                                  {discounts?.endTime ? getTimeFormat(discounts?.endTime) : "n/a"}
                                </h6>
                              </td>
                              <td>
                                <h6 className="mb-0 fw700 fontsize-16">{discounts?.discount}%</h6>
                              </td>
                              <td>
                                <h6 className="mb-0 fw700 fontsize-16">
                                  {discounts?.teesheetSold ? discounts?.teesheetSold : "n/a"}
                                </h6>
                              </td>
                              <td>
                                {discounts?.status === "pending" && courseStatus === 1 && discounts?.addedByAdmin === true &&
                                  <div className="d-flex align-items-center justify-content-center">
                                    <a
                                      href={void 0}
                                      className="fontsize-18 color-800 color-danger ms-3"
                                      onClick={() => onDiscountDelete(discounts)}
                                    >
                                      <em className="far fa-trash-can"></em>
                                    </a>
                                  </div>}
                              </td>
                              <td>
                                {discounts?.status === "pending" && discounts?.addedByAdmin === true && (
                                  <em
                                    className="fa-solid fa-clock fontsize-16 text-danger"
                                    title={discounts?.status ?? ""}
                                  ></em>
                                )}

                                {discounts?.status === "accepted" && discounts?.addedByAdmin === true && (
                                  <em
                                    className="fa-solid fa-check fontsize-16 text-success"
                                    title={discounts?.status ?? ""}
                                  ></em>
                                )}

                                {discounts?.status === "rejected" && discounts?.addedByAdmin === true && (
                                  <em
                                    className="fa-solid fa-close fontsize-16 text-danger"
                                    title={discounts?.status ?? ""}
                                  ></em>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <span className="mb-10 mt-10 text-center">No instant discount added</span>
                    )}
                  </div>
                </div>
              </div>


              <InstantDiscountModel
                showModal={showDiscountModel}
                onCloseClick={closeDiscountModel}
                selectedDiscount={selectedDiscount}
                onAddInstantDiscount={onAddInstantDiscount}
                modalType={modalType}
                deleteDiscount={deleteDiscount}
                currentClubDateTimeLuxon={currentClubDateTimeLuxon}
              />


              <div className="courseDetail__card mb-3">
                <div className="content-center">
                  <h5 className="courseDetail__cardTitle mb-4">
                    Basics about golf course
                  </h5>
                  {courseStatus === 1 && <em className="fa-solid fas fa-edit cursor-pointer" onClick={() => handleEditModal("basics")}></em>}
                </div>
                <div className="row gutters-10">
                  <div className="col _basics">
                    <h6 className="mb-3 fw700">Policies</h6>
                    <p>Walking allowed: {(courseDetails?.club?.walkingAllowed) ? 'Yes' : 'No'}</p>
                    <p>Metal spikes allowed: {(courseDetails?.club?.metalSpikesAllowed) ? 'Yes' : 'No'}</p>
                    <p>Dress Code: {(courseDetails?.club?.dressCode) ? 'Appropriate golf attire' : 'No'}.</p>
                  </div>
                  <div className="col _basics">
                    <h6 className="mb-3 fw700">Practice/Instruction</h6>
                    <p>Driving range: {(courseDetails?.club?.drivingRange) ? 'Yes' : 'No'}</p>
                    <p>Pitching/chipping area: {(courseDetails?.club?.pitchingArea) ? 'Yes' : 'No'}</p>
                    <p>Putting green: {(courseDetails?.club?.puttingGreen) ? 'Yes' : 'No'}</p>
                    <p>Teaching pro: {(courseDetails?.club?.teachingPro) ? 'Yes' : 'No'}</p>
                  </div>
                  <div className="col _basics">
                    <h6 className="mb-3 fw700">Rentals/Services</h6>
                    <p>Carts: {(courseDetails?.club?.cartsForRentalAvailable) ? 'Yes' : 'No'}</p>
                    <p>Clubs: {(courseDetails?.club?.clubsForRentalAvailable) ? 'Yes' : 'No'}</p>
                    <p>Caddies available: {(courseDetails?.club?.caddiesForRentalAvailable) ? 'Yes' : 'No'}</p>
                  </div>
                </div>

                {selectedKey == "basics" && showEditModal && <Edit handleCloseEditModal={handleCloseEditModal} keyVal={"basics"} contentVal={basicsInfo()} clubObjectId={clubId} checkContentEdited={checkContentEdited} courseObjectId={id} merchantId={courseDetails.merchant._id} hasExistingDiscounts={hasExistingDiscounts} discountId={discountId} advanceBookingDuration={advanceBookingDuration} discountProfiles={courseDetails?.discountProfiles} />}
              </div>

              <div className="courseDetail__card mb-3">
                <div className="d-flex justify-content-end">
                  {defaultProductsInfo?.response?.length === 0 && Array.isArray(defaultProductsInfo?.response) && (
                    <button
                      type="submit"
                      onClick={(e) => addMerchantDefaultProducts(e)}
                      className="mt-1 button button-primary add-products-btn button-rounded fontsize-14 fw500"
                    >
                      Add Default Products
                    </button>
                  )}
                  {defaultProductsInfo?.response?.length > 0 && (
                    <h5 className="courseDetail__cardTitle mb-3 flex-grow-1">
                      Products List
                    </h5>
                  )}
                </div>

                <div className="row gutters-10">
                  <div className="col _basics">
                    {defaultProductsInfo?.response?.length === 0 && (
                      <h5 className="courseDetail__cardTitle mt-2 mb-2 no-products-title">No products added!</h5>
                    )}

                    {defaultProductsInfo?.response?.length > 0 && (
                      <div className="_paySection">
                        {/* <h5 className="_paySection-title mb-2">Added Products</h5> */}
                        <div className="row gutters-5">
                          {defaultProductsInfo.response.map((product: any, i: number) => (
                            <div className="col-6 col-md-4 col-lg-3 py-2" key={i}>
                              <div className="_productBlock">
                                <div
                                  className="productImageBlock mb-2"
                                  style={{ width: '100%', height: '150px', overflow: 'hidden' }}
                                >
                                  <img
                                    src={product.images?.length > 0 ? product.images[0].path : ''}
                                    alt={product.title}
                                    className="img-fluid"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                  />
                                </div>
                                <h6 className="text-align-center">Name: <span className="fw600">{product.title}</span></h6>
                                <h6 className="text-align-center">Pricing: <span className="fw600">$ {product.price}</span></h6>
                                <h6 className="text-align-center">Inventory: <span className="fw600">{product.inventory}</span></h6>
                                <h6 className="text-align-center">Max Cart Quantity: <span className="fw600">{product.maxQtyForCart}</span></h6>
                                {product.isApproved === false && (
                                  <h6 className="text-align-center">Status: <span className="fw600 text-danger">Pending</span></h6>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>


            </div>

            <div className="col-12 col-lg-4">
              <div className="courseDetail__card mb-3">
                <div className="row gutters-10 courseDetail__cardUserData">
                  <div className="col-auto">
                    <span className="userImage">
                      <img
                        src="../../assets/images/default-user.png"
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                  </div>
                  <div className="col">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <h3
                          className="mb-0 cursor-pointer"
                          onClick={() => navigate(`/users/bankaccounts/${courseDetails?.merchant?._id}`)}
                        >
                          {courseDetails?.merchant?.firstname}
                        </h3>
                      </div>
                      <NavLink
                        to={`/users/bankaccounts/${courseDetails?.merchant?._id}`}
                        className="text-decoration-underline small bank-details-text"
                      >
                        View Bank Details
                      </NavLink>
                    </div>
                    <p className="mb-1">{courseDetails?.merchant?.email}</p>
                    <p className="mb-1">
                      {courseDetails?.merchant?.phone?.countryCode
                        ? `+${courseDetails?.merchant?.phone?.countryCode} ${courseDetails?.merchant?.phone?.number}`
                        : `${courseDetails?.merchant?.phone?.number}`}
                    </p>
                    <p><strong className="payment-cadence-info">Payout Cadence</strong>: {payoutCandenceInformation?.label ?? "n/a"}</p>
                  </div>
                </div>
              </div>
              <div className="courseDetail__card mb-3">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h5 className="courseDetail__cardTitle mb-4">Booking Timings (Discounted Portal)</h5>
                  {courseStatus === 1 && <em className="fa-solid fas fa-edit cursor-pointer" onClick={() => handleEditModal("bookingtimings")}></em>}
                </div>
                <div className="courseDetail__bookingSection">
                  {courseDetails?.club?.clubTimings?.map((x: any, i: number) => (
                    (x?.day && x?.startHours && x?.endHours && <div className="row align-items-center gutters-10 mb-2" >
                      <div className="col-auto">
                        <label className="daysLabel mb-0 capitalize-class me-0">
                          {x?.day.slice(0, 3).charAt(0).toUpperCase() + x?.day.slice(1, 3).toLowerCase()}
                        </label>
                      </div>
                      <div className="col">
                        <div className="row gutters-10">
                          <div className="col-auto">
                            <h6 className="mb-0 fw700">
                              <span className="fw400">Opening :</span>
                              <span>&nbsp;{convertTime24to12(x?.startHours, x?.startMinutes)}</span>
                            </h6>
                          </div>
                          <div className="col-auto">
                            <h6 className="mb-0 fw700">
                              <span className="fw400">Closing :</span>
                              <span>&nbsp;{convertTime24to12(x?.endHours, x?.endMinutes)}</span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>)
                  ))}
                  {
                    tourismPortalClubTimings?.length > 0 ? <>
                      <div className="row pb-4 pl-2 mt-3">
                        <h5 className="courseDetail__cardTitle mb-4">Booking Timings (Tourism Portal)</h5>
                        {tourismPortalClubTimings?.map((timing: any) => <>
                          <div className="row align-items-center gutters-10 mb-2">
                            <div className="col-auto">
                              <label className="daysLabel my-1 capitalize-class me-0 text-capitalize">
                                {timing.day?.substring(0, 3)}
                              </label>
                            </div>
                            <div className="col">
                              <div className="row gutters-10">
                                <div className="col-auto">
                                  <h6 className="my-1 fw700">
                                    <span className="fw400">Opening :</span>
                                    <span>&nbsp;{convertTime24to12(timing?.startHours, timing?.startMinutes)}</span>
                                  </h6>
                                </div>
                                <div className="col-auto">
                                  <h6 className="my-1 fw700">
                                    <span className="fw400">Closing :</span>
                                    <span>&nbsp;{convertTime24to12(timing?.endHours, timing?.endMinutes)}</span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>)}
                      </div>
                    </> : <></>
                  }
                </div>


                {selectedKey == "bookingtimings" && showEditModal && <Edit handleCloseEditModal={handleCloseEditModal} keyVal={"bookingtimings"} contentVal={{ clubTimings: mappedClubTimings(courseDetails?.club?.clubTimings), tourismPortalClubTimings: courseDetails?.club?.tourismPortalClubTimings || [], advanceBookingDuration: courseDetails?.club?.advanceBookingDuration, tourismPortalAdvanceBookingDuration: courseDetails?.club?.tourismPortalAdvanceBookingDuration }} clubObjectId={clubId} checkContentEdited={checkContentEdited} courseObjectId={id} merchantId={courseDetails.merchant._id} hasExistingDiscounts={hasExistingDiscounts} discountId={discountId} advanceBookingDuration={advanceBookingDuration} discountProfiles={courseDetails?.discountProfiles} />}
              </div>

              <div className="courseDetail__card mb-3">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h5 className="courseDetail__cardTitle mb-3">Integration</h5>
                  {courseStatus === 1 && clubRatingsError?.length === 0 && clubRatingsData?.length > 0 ? <em className="fa-solid fas fa-edit cursor-pointer" onClick={() => handleEditModal("clubRatings")}></em> : ''}
                </div>
                <div className="logoBlock logoTee">
                  <img
                    src={(courseDetails?.course?.type === 'teeon') ? "../../assets/images/logo-tee-on.png" : "../../assets/images/logo-chronogolf.png"}
                    alt="../../assets/images/icons/verify.svg"
                    className="img-fluid"
                    width={"100px"}

                  />
                </div>
                {courseDetails?.thirdPartyDetails?.map((each: any, index: number) => {
                  return (
                    <div className="courseDetail__integrationRow pt-0">
                      <div className="logoBlock logoTee">
                        <img
                          src={(each.clubType === 'teeon') ? "../../assets/images/logo-tee-on.png" : "../../assets/images/icons/verify.svg"}
                          alt=""
                          className="img-fluid"
                          width={"40px"}
                        />
                      </div>
                      <div className="content">
                        <label className="_label mb-1">User name</label>
                        <h6>{each.username}</h6>
                        <div className="statusBlock">
                          {courseDetails?.course?.syncStatus === 'success' ? <span className="connected fw700 d-flex algin-items-center">
                            <img
                              src="../../assets/images/icons/verify.svg"
                              height={18}
                              width={18}
                              alt=""
                            />{" "}
                            Connected
                          </span> :
                            <span className="disconnected fw700 d-flex algin-items-center">
                              <img
                                src="../assets/images/icons/warning-circle.svg"
                                height={18}
                                width={18}
                                alt=""
                              />{" "}
                              Discontented
                            </span>
                          }

                        </div>
                      </div>

                    </div>
                  )
                })}

                {courseDetails?.course?.type !== 'teeon' && <div className="mt-4">
                  {clubRatingsError && clubRatingsError?.length > 0 ? <> <div className="isInvalidMessage text-danger mt-3">{clubRatingsError}</div></> : <><div className="courseDetail__discountBlock">
                    <div className="ratingBlockHead">
                      <h6 className="fontsize-14 fw600 color-800">
                        Discounted Portal Rate
                      </h6>
                      <label className="ratingBlock">
                        {courseDetails?.club?.discountedPortalRate?.rateName || "NA"}
                      </label>
                    </div>
                    <div className="ratingBlockHead">
                      <h6 className="fontsize-14 fw600 color-800">
                        Tourism Portal Rate
                      </h6>
                      <label className="ratingBlock">
                        {courseDetails?.club?.tourismPortalRate?.rateName || "NA"}
                      </label>
                    </div>
                  </div>
                  </>}
                  {selectedKey == "clubRatings" && showEditModal && <Edit handleCloseEditModal={handleCloseEditModal} keyVal={"clubRatings"} clubObjectId={clubId} checkContentEdited={checkContentEdited} contentVal={clubRatingsData} clubRatings={existingClubRatings()} />}
                </div>}
              </div>

              <div className="courseDetail__card mb-3">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h5 className="courseDetail__cardTitle mb-4">Discounts</h5>
                  {clubDiscounts?.length > 0 && courseStatus === 1 ? <em className="fa-solid fas fa-edit cursor-pointer" onClick={() => handleEditModal("discounts")}></em> : ''}
                </div>
                <div className="courseDetail__discountBlock">
                  {clubDiscounts?.map((each: any, index: number) => {
                    return (
                      <>
                        <h6 key={index} className="fontsize-14 fw600 color-800 mb-2">
                          {each?.name}
                        </h6>
                        <div className="d-flex flex-wrap">
                          {each.matchedObjects?.map((eachDisc: any, index: number) => {
                            const lebel = buildLabel(eachDisc.days, eachDisc.discount);
                            return (<label className="discountBadge">
                              {lebel}
                            </label>)
                          })}
                        </div>
                      </>
                    )
                  })}

                </div>
                {selectedKey == "discounts" && showEditModal && <Edit handleCloseEditModal={handleCloseEditModal} keyVal={"discounts"} contentVal={getavailableDiscounts(courseDetails.discounts)} clubObjectId={clubId} checkContentEdited={checkContentEdited} courseObjectId={id} merchantId={courseDetails.merchant._id} hasExistingDiscounts={hasExistingDiscounts} discountId={discountId} advanceBookingDuration={advanceBookingDuration} discountProfiles={courseDetails?.discountProfiles} />}

              </div>

              {hasExistingDiscounts?.length > 0 && (
                <div className="courseDetail__card mb-3">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5 className="courseDetail__cardTitle mb-4">Pending Discounts</h5>
                  </div>

                  <div className="courseDetail__discountBlock">
                    {hasExistingDiscounts.map((discount: any, index: number) => (
                      <div key={index} className="d-flex flex-wrap">
                        <label className="discountBadge">
                          <span className="strike">{buildLabel(discount.previousDays, discount.previousDiscount)}</span> <br /> {buildLabel(discount.updatedDays, discount.updatedDiscount)}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      }
    </>
  );
};

export default CourseDetail;
