import * as CryptoJS from "crypto-js";
import { isExpired, decodeToken } from "react-jwt";
export const getStatusDetail = (status: number) => {
  switch (status) {
    case 1:
      return {
        label: 'Active',
        class: 'status--active'
      }
    case 2:
      return {
        label: 'Inactive',
        class: 'status--danger'
      }
    default:
      return {
        label: 'Completed',
        class: 'status--success'
      }
  }
}


export const capitalizeString = (text: string) => {
  if(!text)
    return text;
  let modStr = text[0].toUpperCase() + text.slice(1);
  return modStr;
}

export const mapTimeZone = (zoneObject:{timezone?:string|undefined})=>{
  let timeZone = "Atlantic/Bermuda";
  if(zoneObject && zoneObject.timezone && typeof zoneObject.timezone === "string"){
    timeZone = zoneObject.timezone;
  }
  return timeZone;

}

export const capitalizeFullString = (str: string): string => {
  if (!str) return '';
  str = str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
  return str
}

export const disableNumWheelBehaviour = (e: any) => {
  const target = e.target;
  target.blur();
  setTimeout(() => {
    target.focus();
  }, 0);
}

export const mapWeatherCodeType = (weatherCode: string) => {

  switch (weatherCode) {
    case "01d": // Clear Sky Day
      return "../../../assets/images/icons/weather/Clear_Day_Icon.png"
    case "01n": // Clear Sky NIght
      return "../../../assets/images/icons/weather/Clear_Day_Night_Icon.png"
    case "02n": // Few clouds night
    case "02d": // Few clouds day
      return "../../../assets/images/icons/weather/Few_Clouds_Day.png"
    case "03n": // Scatterd clouds night
    case "03d": // Scatterd clouds
      return "../../../assets/images/icons/weather/Scattered_Clouds.png"
    case "04d": // Broken clouds Day
    case "04n": // Broken clouds Night
      return "../../../assets/images/icons/weather/Broken_Clouds.png"
    case "09d": // Shower rain day
    case "09n": // Shower rain night
      return "../../../assets/images/icons/weather/Shower_Rain.png"
    case "10d": // Rain Day
     return "../../../assets/images/icons/weather/Rain_Day.png"
    case "10n": // Rain Night
      return "../../../assets/images/icons/weather/Rain_Night.png"
    case "11d": // Thunderstorm Day
    case "11n": // Thunderstorm Night 
      return "../../../assets/images/icons/weather/Thunderstorm.png"
    case "13d": // Snow Day
    case "13n": // Snow Night
      return "../../../assets/images/icons/weather/Snow.png"
    case "50d": // Mist Day
    case "50n": // Mist Night
      return "../../../assets/images/icons/weather/Mist.png"
    default:
      return "../../../assets/images/icons/weather/Clear_Day_Icon.png"
  }
}

export const decryptData = (stringToEncrypt: string, encryptionKey: string): any => {
  
  if (!stringToEncrypt || !stringToEncrypt.trim().length) {
      throw new Error('Invalid string value passed to encrypt');
  }

  if (!encryptionKey || !encryptionKey.trim().length) {
      throw new Error('Invalid string value passed to AES Secret key');
  }

  const bytes = CryptoJS.AES.decrypt(stringToEncrypt, encryptionKey);
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedText;
}

export const decodeJwt = (jwtString: string|null): any => {
  if (jwtString) {
    if (isExpired(jwtString)) {
      throw new Error("Redirect link expired");
    }
    return decodeToken(jwtString);
  }
}