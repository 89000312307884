import React, { FC, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './merchantSignup.scss';
import MerchantList from './components/merchant-list/merchant-list';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import IntegolfLoader from '../../components/integolf-loader/integolf-loader';
import { usePagination } from '../../contexts/PaginationContext';
import Filters from './components/filters/filters'
import { useUserFilters } from './merchantSignup.context';
import { useResendAcceptanceEmail, useResendSignupEmail } from './merchantSignup.hooks';
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
let debounce = require('lodash.debounce');


interface UsersProps { }

const Users: FC<UsersProps> = () => {
  const [showTab, setShowTab] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const onShowFilter = () => {
    document.body.classList.add("overflow-hidden");
    setShowFilters(true);
  };
  const hideFilterEvent = (value: any) => {
    document.body.classList.remove("overflow-hidden");
    setShowFilters(false);
  };

  const applyFilterEvent = (value: any) => {
    hideFilterEvent(false);
  };
  const toggleNavTab = (value: any) => {
    setShowTab(value);
  };
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();
  const {
    courseStatus,
    serchTerm,
    setSearchTerm,
    appliedFilters
  } = useUserFilters();
  const getMerchant = async (accessToken: string) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/listMerchantSignups`,{params: {
      limit,
      skip
    },
      headers: {
        accesstoken: `${accessToken}`,
      },
    });
    return response.data;
  };
  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data, mutate: getUserscall } = useMutation('users', () => getMerchant(accessToken!));


  const changeUserStatus = async (accessToken: string, userObject: any) => {
    let bodyData: any = {
      "email": userObject?.email,
      "status": userObject?.status,
      "portal":userObject?.portal,
    }

    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/updateMerchantSignup`, bodyData, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    })
    .catch(err=>{
      toast.error(err.message)
    })
    if(response?.data?.response?.status){
      toast.success(response?.data?.response?.status)
    }
    
    return response?.data || {};
  }

  const { mutate: markUserActiveInactive, data: updatedStatusData } = useMutation('markUserActiveInactive', (userObject: any) => changeUserStatus(accessToken!, userObject));
  const { resendSignupEmail } = useResendSignupEmail(accessToken);
  const { resendAcceptEmail} = useResendAcceptanceEmail(accessToken);
  useEffect(() => {
    if (data?.response?.count && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }

  }, [data?.response?.count, skip]);

  useEffect(() => {
    getUserscall();
  }, [skip, getUserscall, appliedFilters, serchTerm, updatedStatusData,itemsPerPage])

  useEffect(() => {
    setCurrentPage(1);
  }, [appliedFilters, serchTerm])


  return (
    <>
      <div className="usersWrapper">
        <h1 className="mb-3 pageTitle">Merchant Signups</h1>
        <div className="col-4">
          {/* <div className="searchBlock">
            <input onChange={(e) => {
              setSearchTerm(e.target.value)
            }} type="text" className="form-control" placeholder="Search by name, email" />
          </div> */}
        </div>
        <div className="container-fluid p-0">
          <div className="row justify-content-between mb-2">
            <div className="col-12 col-lg">
            </div>
            {/* <div className="col-12 col-lg-auto">
              <button
                type="button"
                className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
                onClick={() => onShowFilter()}
              >
                <em className="icon-filter me-2"></em> Filters
              </button>
            </div> */}
          </div>
          {data?.response?.count === 0 && <IntegolfDataNotFound message={"Merchants not found"}/>}
          {data?.response ? 
          <MerchantList 
          updatedStatusData={updatedStatusData} 
          markUserActiveInactive={markUserActiveInactive} 
          data={data?.response?.result} 
          resendSignupEmail={resendSignupEmail}
          resendAcceptEmail={resendAcceptEmail}
          />
            :
            <IntegolfLoader show />}
        </div>
      </div>
      {showFilters && (
        <div className="asideFilterWrapper">
          <div className="asideFilterInnerWrap">
            <Filters
              showFilterComponent={showFilters}
              handleApplyFilters={applyFilterEvent}
              handleCloseFilterModal={hideFilterEvent}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Users;
