import { useMutation, useQuery } from 'react-query';
import React, { FC, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';

const resendSignupEmailReq = async (accessToken: string, userObject: any) => {
    let bodyData: any = {
      "email": userObject?.email,
      "portal":userObject?.portal,
    }

    const response = await axios.post(`${process.env.REACT_APP_USER_API_BASEURL}/users/resendMerchantSignupEmail`, bodyData, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    })
    .catch(err=>{
      toast.error(err.message)
    })
    if(response?.data?.message){
      toast.success("Confirmation email sent successfully")
    }
    
    return response?.data || {};
  }

  const resendAcceptEmailReq = async (accessToken: string, userObject: any) => {
    let bodyData: any = {
      "email": userObject?.email,
      "portal":userObject?.portal,
    }

    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/resendMerchantSignupStatusEmail`, bodyData, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    })
    .catch(err=>{
      toast.error(err.message)
    })
    if(response?.data?.code){
      toast.success("Acceptance email sent successfully")
    }
    
    return response?.data || {};
  }


export const useResendSignupEmail = (accessToken:any)=>{
  const { mutate: resendSignupEmail, data: resendSignReqData } = useMutation('resendSignupEmail', (userObject: any) => resendSignupEmailReq(accessToken!, userObject));
  return {
    resendSignupEmail,
    resendSignReqData
  }
}

export const useResendAcceptanceEmail = (accessToken:any)=>{
    const { mutate: resendAcceptEmail, data: resendAcceptReqData } = useMutation('resendAcceptEmail', (userObject: any) => resendAcceptEmailReq(accessToken!, userObject));
    return {
        resendAcceptEmail,
        resendAcceptReqData
    }
  }