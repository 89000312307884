import "./tournament-bookings-list.scss";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { usePagination } from "../../../../../contexts/PaginationContext";
import Pagination from "../../../../../components/Pagination";
import { getStatusObj } from "../../../../../utils/constants";
import { Dropdown } from "react-bootstrap";
const getUserName = (user: any): string => {
  const strippedName = user?.name?.replace(/\s+/g, '');
  if (user?.name && strippedName !== 'undefined') {
    return user.name;
  }
  else if (user?.firstname) {
    if (user.lastname) {
      return `${user.firstname} ${user.lastname}`;
    }
    return user.firstname;
  }
  return 'Guest';
}
const TournamentBookingList = ({ data }: { data: any }) => {
  const { currentPage, totalItems, itemsPerPage } = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate=useNavigate()
  return (
    <>
   {data?.length>0 && 
    <div className="courseslistWrapper mt-4">
      <div className="tableLayout">
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Status</th>
                <th>Tournament Name</th>
                <th>Tournament Type</th>
                <th>Club Name</th>
                <th>Payment Type</th>
                <th>Booked On</th>
                <th>Payment</th>
                <th className="width-50">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((e: any, j: number) => {
                const status = getStatusObj(e?.status?.value)
                let user = (e?.user)
                // let userName= (user?.name) ? user?.name : (user?.firstname) ? `${user?.firstname} ${user?.lastname}` : 'Guest'
                const userName = getUserName(user)
                let userPhone = (e?.user?.phone)? `+${e.user?.phone?.countryCode}${e.user?.phone?.number}` : 'n/a';
                return (
                  <tr key={j}>
                    <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                    <td>
                      <div className="userContent align-items-center">
                        <span className="imgBlock rounded-circle">
                          <img
                            src={e?.profileImage?.[0]?.path ?? "../../assets/images/default-user.png"}
                            alt=""
                            className="img-fluid"
                          />
                        </span>
                        <div className="content">
                          <NavLink state={{ id: e._id }} to={'detail'}>
                            <h5 className="mb-0" style={{ textTransform: 'capitalize' }}>{userName}</h5>
                            </NavLink>
                        </div>
                      </div>
                    </td>
                    <td style={{whiteSpace: 'nowrap'}}>{e?.user?.email}</td>
                    <td style={{whiteSpace: 'nowrap'}}>{userPhone}</td>
                    <td>
                      <label style={{ background: status.color, color: status.fontColor, textTransform: 'capitalize' }} className={`mb-0 status`}>
                        {status.label}
                      </label>
                    </td>
                    <td style={{minWidth:'140px'}} className="text-capitalize">{e?.tournamentName}</td>
                    <td>
                        {e?.tournamentInfo?.League ? "League" : "Tournament"}
                    </td>
                    <td style={{textAlign:'center',minWidth:'130px'}} className="text-capitalize">{e?.tounamentcourse?.CourseName}</td>
                    <td className="text-capitalize">
                     {e?.paymentType?.Description ?? "n/a"}
                    </td>
                    <td style={{textAlign:'center',minWidth:'200px'}}>
                      {`${dayjs(e?.createdAt).format('MMMM D YYYY, h:mm a')}`}
                    </td>
                    <td style={{minWidth: '120px'}}>
                        ${e?.totalPrice}
                    </td>
                    <td>
                    <div className="listActionDropdownBlock">
                        <Dropdown align="end">
                          <div className="text-center">
                            <Dropdown.Toggle className="toggleButton">
                              <em className="fa-solid fa-ellipsis-vertical"></em>
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu>
                            <NavLink state={{ id: e._id }} to={'detail'} className="dropdown-item fw600">
                              View
                            </NavLink>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {
              totalItems > 10 && 
              <Pagination />
            }
          </div>
        </div>
      </div>
    </div>
}
</>
  );
};
export default TournamentBookingList;